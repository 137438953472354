.custom-login_page{
  ///*** reseau icon ***///
  .icon-svg {
    width: 48px;
    height: 48px;
    background-color: var(--white);
    border-radius: 100px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    padding: 3px;
  }
  .icon-svg:hover > path {
    fill: var(--white);
  }

  ///*** section highlight products ***///
  .productsHighlights {
    background-color: var(--white);
    border-radius: 10px;
  }
  .categoryContent {
    height: 205px;
  }

  .categoryContent .imgBlock {
    height: 90px;
    margin: 7px;
  }

  .square-product-web {
    height: unset;
  }

  .categoryContent .containerProductBlock {
    min-height: unset;
  }

  .discount {
    font: normal normal 700 14px/15px Roboto;
    height: 40px;
  }
  .productPrice {
    font-size: 12px;
    top: -24px !important;
  }

  .productTitle{
    max-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }
}