///////////// Menu catégories /////////////
.categoryListContentMobile {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .categoryListMobile {
    width: 100%;
    margin-top: 65px;
  }

  .dropdown-categories-menu {
    display: none;
    position: absolute;
    top: 100%;
    z-index: 10;
    padding: 20px;
    background-color: white;
    font: normal normal bold 40px/30px Raleway;
    text-align: center;
    width: 85%;
    box-shadow: 0px 15px 20px 6px #9090909c;
  }

  .btn-categories-menu {
    text-align: center;
    font: normal normal bold 40px/30px Raleway;
    width: 100%;
    background-color: #EBF4F3;
  }
}
//////////

.categorySearchMobile {
  .searchBar-formGroup {
    background-color: #98be58;
    height: 83px;
    font: normal normal normal 20px/20px Raleway;
    margin-top: 2%;
    margin-left: 12%;
    z-index: 1;
    width: 75%;

    @include reducbox-media--sm {
      margin-top: 0;
    }

    .form-control:active,
    .form-control:focus,
    .form-control:focus:active {
      background-color: #98be58;
      color: white;
      width: 90%;
    }

    ::placeholder {
      color: white;
      opacity: 1; /* Firefox */
    }

    .inputSearchBar {
      background-color: #98be58;
      font: normal normal bold 40px/40px Raleway !important;
      text-align: center;
      margin-left: 70px;
    }

    .searchBarImg {
      margin-left: -50px;
      position: absolute;
      margin-top: -19.7%;
      width: 410% !important;
      height: 130px !important;
    }

    .btn-search-delete{
      margin-top: 10px;
      margin-right: 10px;
    }
  }
}


.categoryContentMobile {
  .containerProductBlock{
    border: 8px solid #adadab;
    border-radius: 30px
  }

  .productBlock{
    border-radius: 18px;
  }

  .productPrice {
    top: -33px;
    padding: 0;
    min-width: auto;
  }

  .priceFrom {
    font: normal normal bold 26px/22px Raleway;
  }

  .discount {
    width: 43%;
    height: 110px;
    font: normal normal bold 40px/40px Roboto;
    .text-is-small{
      font-size: 28px;
    }
  }

  .productTitle {
    font: normal normal 33px/36px Raleway;
    margin: 2%;
  }

  .imgBlock {
    height: 235px;
  }
}

.container-div-overflow {
  position: relative;
  width: 100%;
  overflow: scroll;
}
/////// shop homepage ///////
.padding-col {
  padding-left: 15px;
  padding-right: 15px;
}

/// section "à la Une" ///
.container-une-images {
  height: 284px;
}

.une-images-mobile {
  width: 70%;
  height: 284px;
  position: absolute;
}

/// section top products ///
.top-overflow {
  width: 38%;
  height: 420px;
  position: absolute;
  margin: 36px 0;
}

.container-product-mobile {
  height: 500px;
}

.container-product-mobile .overlay-seemore{
  font-size: 55px;
  border-radius: 21px;
}
////////////

//// paginate ///////
.paginate-mobile {
  .page-link {
    font-size: 45px;
    padding: 1.5rem 1.75rem;
    margin-left: 0px;
  }
  .page-item:last-child .page-link {
    border-top-right-radius: 70%;
    border-bottom-right-radius: 70%;
  }
  .page-item:first-child .page-link {
    border-top-left-radius: 70%;
    border-bottom-left-radius: 70%;
  }
}
.shop-paginate.paginate-mobile .page-link {
  border-radius: 66px;
  min-width: 100px;
  text-align: center;
}

