html,
body {
    height: 100%;
}

body.login-page {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 110px;
    //background: url(../images/bg.jpg) no-repeat center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.form-signin {
    width: 100%;
    max-width: 420px;
    padding: 15px;
    margin: auto;

    .form-control {
        position: relative;
        box-sizing: border-box;
        height: auto;
        padding: 10px;
        font-size: 16px;
    }

    .form-control:focus {
        z-index: 2;
    }
}

.carousel-control-icon{
    background-color: black;
    width: 30px;
    height: 30px;
    margin: auto;
    border-radius: 18px;
}

#homepagefooter a,
#homepagefooter a:hover {
    margin-left: 30px;
    text-decoration: none;
    color: white;
}

#login {
    max-width: 420px;
    padding: 20px;

    input,
    .input-group-prepend,
    .input-group-text {
        background-color: #eeeeee;
        border: 0;
        box-shadow: none;
    }

    label,
    p {
        margin: 0;
        font-size: 12px;
        font-weight: 500;
        color: #1d1d1b;
    }

    .logo {
        margin: 1.5rem 0;
    }

    .links {
        margin-top: 50px;
        padding: 20px 0 0;
        border-top: 1px solid #eeeeee;
    }
}
.cbLogo {
    background: transparent url("../images/moyenPaiement/giftCard.png") no-repeat center;
    background-size: cover;
    width: 140px;
    height: 120px;
    border: 0;
    position: relative;
    top: -40px;
    left: 20px;
    z-index: 0;
}

.grantLogo {
    background: transparent url("../images/moyenPaiement/sub.png") no-repeat center;
    background-size: cover;
    width: 140px;
    height: 120px;
    border: 0;
    position: relative;
    top: -40px;
    left: 20px;
    z-index: 0;
}

.refundLogo {
    background: transparent url("../images/moyenPaiement/refundLogo.svg") no-repeat center;
    background-size: cover;
    width: 140px;
    height: 120px;
    border: 0;
    position: relative;
    top: -40px;
    left: 20px;
    z-index: 0;
}

.gest-div {
    width: 100%;

    padding: 10px;
    margin: 2%;
}

.badge-notification {
    position: relative;
    top: 0;
}

.card-link {
    background-color: white;
}

.card-link:hover {
    text-decoration: none;
}

.card-container {
    min-width: 30%;
    max-width: 31%;
    margin: 10px;
}

.card-icon {
    z-index: 2;
}

.card-body {
    background-color: #85a7d4;
    height: 100%;
    width: 100%;
    box-shadow: 2px 2px 6px gray;
}

.card-value {
    color: white;
    text-transform: uppercase;
    font-size: 40pt;
    text-align: right;
    margin-top: 10pt;
}

.card-title {
    color: white;
    text-transform: uppercase;
    font-size: large;
}

.card-title-strong {
    color: white;
    text-transform: uppercase;
    font-size: x-large;
    word-break: break-word;
}

.card-content {
    color: white;
    font-size: medium;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.card-content-gest {
   /* font-size: medium;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;*/ /* number of lines to show */
    /*-webkit-box-orient: vertical;*/
}

.you-should-not-pass{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    .label{
        color: red;
        font-size: large;
        text-align: center;
        padding: 15px 30px;
        border: 10px double red;
        background-color: rgb(255 255 255 / 30%);
        border-radius: 35px;
        text-transform: uppercase;
        transform: rotate(-25deg);
        text-shadow: 2px 0 0 #dc0000;
    }
}

.card-mobile{
    .label{
        font-size: xx-large;
        border-radius: 50px;
        padding: 25px 40px;
    }
}

body.body-with-search-fixed {
    padding-top: 270px;
}
