// Body
//$body-bg: #f8fafc;

// Typography
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
//$green: #38c172;
$green: #60ac45; // green logo reducbox
$teal: #4dc0b5;
$cyan: #6cb2eb;
$gray-light : #adadab;

//fontAwesome
$fa-font-path: "/fonts";

// statistics colors
$backgroundColorSetRegular : ("#E6CFFB","#E8DFCF","#D2DFFC","#B2B6DF","#EFE5FD","#9CB9E2","#2E8B57","#1D7A46");
$backgroundColorSetColorBlind : ("#000000","#DCA137","#6DB3E4","#459B76","#EFE361","#2E72AD","#C76426","#C17EA5");