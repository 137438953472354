.bg-dark {
    background-color: #1a202c !important;
}

.megamenu-li {
    position: static;
}

.is-active {
    color: $primary !important;
    background-color: white;
}

.megamenu {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    padding: 1.5rem 1rem;
    margin-top: 0;
    border: 0px;
    border-radius: 0px;
}

#mainnavbar {
    .tab-link {
        text-transform: uppercase;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        margin-left: 0 !important;
    }

    h5 {
        text-transform: uppercase;
        line-height: 27px;
        font-size: 24px;
        font-weight: 300;
        border-bottom: 1px solid $primary;
    }
}

/* cette partie change et produit une animation sur le bouton de fermeture du menu (version mobile) 
suppression des styles existants */
.navbar-toggler {
    background: none;
    border: none;

    /* remove the blue outline when active or focused */
    &:active,
    &:focus {
        outline: 0;
    }

    /* basic styles for each icon bar */
    .icon-bar {
        display: block;
        width: 22px;
        height: 2px;
        border-radius: 1px;
        margin: 4px 0 4px 0;
        transition: all 0.2s;

        /* custom .navbar-dark .icon-bar background */
        .navbar-dark & {
            background: #ffffff;
        }

        /* .navbar open top .icon-bar rotated down 45° */
        &:nth-of-type(1) {
            transform: rotate(45deg);
            transform-origin: 10% 10%;
        }

        /* .navbar open middle .icon-bar invisible */
        &:nth-of-type(2) {
            opacity: 0;
            filter: alpha(opacity=0);
        }

        /* .navbar open bottom .icon-bar rotated up 45° */
        &:nth-of-type(3) {
            transform: rotate(-45deg);
            transform-origin: 10% 90%;
        }
    }

    /* styles for when .navbar is closed */
    &.collapsed {
        .icon-bar {

            /* .navbar closed top .icon-bar no rotation - straight */
            &:nth-of-type(1) {
                transform: rotate(0);
            }

            /* .navbar open middle .icon-bar visible */
            &:nth-of-type(2) {
                opacity: 1;
                filter: alpha(opacity=100);
            }

            /* .navbar open bottom .icon-bar no rotation - straight */
            &:nth-of-type(3) {
                transform: rotate(0);
            }
        }
    }
}

.edit-contract{
    .nav-tabs .nav-link.active{
        background-color: #eeeeee;
        border-color: #3c4957 #3c4957 #eeeeee;
    }

    .nav-tabs {
        border-bottom: 1px solid #3c4957;
    }
}
