$primary:#98be58;

// Bootstrap
@import "bootstrap";

// Font awesome
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';


// Fonts
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,600');
@font-face {
 font-family: fuzzy;
 src: url('../fonts/fuzzy-bubbles-v5-latin-700.woff2');
}


// Variables
@import "variables";

// custom css
@import "common", "loginPage", "homepage", "navbar", "product", "utils.media-queries", "config.material",
 "showcasehomepage", "showcase", "header", "cart", "mytooltip", "modules", "showcasehomepagemobile", "homepagemobile",
"productMobile","headermobile", "statistics", "administation", "searchBar";
