.litle-searchBar-formGroup {
    background-color: #98be58;
    height: 61px;
    text-align: center;
    color: white;
    font: normal normal normal 18px/18px Raleway;
    z-index: 1;
    position: relative;
    margin-bottom: 20px;

    @include reducbox-media--sm {
        margin-top: 0%;
    }
    /*background: url('../../public/images/Loupe.svg') no-repeat;*/

    
    .form-control:active,
    .form-control:focus,
    .form-control:focus:active {
        background-color: #98be58;
        color: white;
        width: 90%;


    }    

    ::placeholder {
        color: white;
        opacity: 1; /* Firefox */
      }

    .inputSearchBar {
        background-color: #98be58;
        color: white;        
        font: normal normal normal 18px/18px Raleway;
        text-align: center;
        margin-left: 40px;
        width: 85%;
        margin-top: 3%;

    }
    .searchBarImg {
        margin-top: -9px;
        margin-left: -29px;
        width: 100px !important;
    }
}

.litle-categoryListContent {
    text-align: center;
    font: normal normal bold 17px/35px Raleway;
    z-index: 1;
    position: relative;
    width: 100%;
    margin-bottom: 20px;

    .litle-categoryBlock {
        height: 52px;
        text-align: center;
        margin-right: 10px;
        margin-left: 10px;
        padding: 0px;
        display: flex;
        align-items: center;
        color: black;
        background-color: white;
        cursor: pointer;
        height: 35px;
        border: 2px solid #929ea5;
    }

    .categoryName {
        z-index: 2;
        width: 100%;
        padding-top: 0;
    }

    .selectedcategory {
        width: auto;
        flex-grow: 1;
        min-height: 100px;
    }
    

}

///************* paginate ***************////

.paginate {
    margin-top: 3%;
}

.shop-paginate{
    .page-link {
        font-family: "fuzzy", sans-serif;
        color: #797978;
        border-radius: 25px;
        border: 0;
        min-width: 36px;
        z-index: 2 !important;
        margin-left: 0px;
    }
    .page-item:last-child .page-link {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
    }
    .page-item:first-child .page-link {
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
    }
}

///****************************////
  .category-title {
    font: normal normal bold 30px fuzzy;
  }

.autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 900;
    width:85%;
    top: 70%;
    left: 40px;
    right: 0;
}

.autocomplete-items div {
    position: relative;
    z-index: 900;
    padding: 10px;
    cursor: pointer;
    background-color: grey;
    border-bottom: 1px solid #d4d4d4;
}

/*when hovering an item:*/
.autocomplete-items div:hover {
    background-color: #e9e9e9;
    color:black;
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
    background-color: darkgrey;
    color:black;
}

.litle-searchBar-formGroup-admin {
    background-color: #98be58;
    height: 62px;
    text-align: left;
    color: white;
    font: normal normal normal 18px/18px Raleway;
    z-index: 1;
    position: relative;
    width: 103.8%;
   // margin-bottom: 20px;

    @include reducbox-media--sm {
        margin-top: 0%;
    }

    @include reducbox-media--sm {
        margin-top: 0%;
    }

    .form-control:active,
    .form-control:focus,
    .form-control:focus:active {
        background-color: #98be58;
        color: white;
        width: 90%;
    }    

    ::placeholder {
        color: white;
        opacity: 1; /* Firefox */
      }

    .inputSearchBar {
        background-color: #98be58;
        color: white;        
        font: normal normal normal 18px/18px Raleway;
        width: 85%;
        @media only screen and (max-width: 1224px){
            margin-left: 26px;
        }
    }
    .searchBarImg {
        margin-top: -9px;
        margin-left: -29px;
        width: 100px !important;
    }
}