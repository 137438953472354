#product {
    padding-top: 4rem;
    padding-bottom: 4rem;
    color: #1d1d1b;

    h1 {
        text-transform: uppercase;
        border-bottom: solid 1px #ccc;
    }

    

    /* .text-frame,
    .product-description,
    .location-title {
        color: #1d1d1b;
    } */

    /*     .text-is-grey{
        color: #1d1d1b;
    }

    .text-is-small{
        font-size: 14px;
        font-weight: 300;
    }

    .text-is-very-small{
        font-size: 12px;
        font-weight: 300;
    }
 */

    .text-frame,
    .image-frame {
        border: 1px solid #e4e4e0;
        padding: 1.5rem;
    }

    .image-frame {
        text-align: center;
    }

    .location-title {
        margin: 0 0 11px;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 500;
    }

    .location-title i {
        margin-right: 1rem;
    }
}

.addendum {
    display: none;
    overflow: hidden;
    position: absolute;
    padding: 1.5rem;
    right: 20%;
    width: 320px;
}
.productTitle{
    font: normal normal bold 40px/45px Raleway;
}
.productSubtitle{  
    font: italic normal 300 17px/45px Raleway;
}

.productDescription{
    text-align: left;
    //font: normal normal 400 12px/19px Raleway;
}

.productShows {
    font: normal normal bold 19px/19px Raleway;
    margin:0px;
    padding:0px;
}
.productlieu{    
    margin:0px;
    padding:0px;
}

.lstproduct{
    margin-top:2%;
}
.hr-declination{
    width: 40%;
    border-top: 3px solid  $gray-light;
}

.form-declination{
    background-color: $gray-light;
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 10px;
    color: white;
}
.product-terms-of-use-modal{
    border: 4px solid gray;
    border-radius: 30px;
    .modal-header{
        border-bottom: 0;
    }
    .modal-footer{
        border-top: 0;
    }
    img{
        max-width: 100%;
    }
}

.declinaison{
    width: 27%;
    border: 2px solid $gray-light;
    border-radius: 30px;
    padding-top: 40px;
    text-align: center;
    margin: 20px 2%;
    
    .discountRate{
        margin:0px;
        padding:0px;
    }
    
    .discountRateWonderbox{
        margin-left:95.2%;
    }
    .declinaisonTitle{
        font: normal normal 900 20px/33px Raleway;
    }
    
    .declinaisonDescription{
        font: normal normal medium 12px/16px Raleway;
    }

    .kiosquePrice{
        background-color: #F4F4F4;
        background: #F4F4F4 0% 0% no-repeat padding-box;
        padding: 10px;
        width: 35%;
    }

    .ReducboxPrice{
        color : #FFF;
        background: #3B4957 0% 0% no-repeat padding-box;
        padding: 10px;
        width: 35%;
    }
    .quatity{
        width: 25%;
    }

    .titleDescriptionPriceLabel{
        @extend .font-size-12;
        margin:0;
        width: 100%;
        font-family: 'Raleway';
    }
    .descriptionPriceLabel{
        font: normal normal 200 12px/16px Raleway;
        margin:0;
    }
    .priceDecription{
        font: normal normal bold 33px/35px Ubuntu;
        font-size: 2vw;
        margin:0;
    }
    .priceRow{        
        position: relative;
        top: 0px;
        left: 0;
    }
}

.info{
    @extend .font-size-12;
    margin-top: 20px;
    text-align: left;
    word-break: break-word;
    font-family: 'Raleway';
}

.infoDetail{
    @extend .font-size-12;
    font-family: 'Raleway';
    border: 1px solid #3B4957 !important;
    border-radius: 25px;
    text-align: center;
    padding: 10px;
    margin-top: 20px;
    word-break: break-word;
}

.infoDetailLieu{
    border: 2px solid #98be58 !important;
    border-radius: 2px;
}

.frame-product-choice{
    border-radius: 30px;
    border: 1px solid $secondary;
}

.sizeBlockDeclinaison{
    width:35%;
    margin-left:2%;
    padding: 2%;
    height: 15%;
}

.sizeBlockDeclinaison2{
    width: 100%;
    margin-left: 0;
    padding: 2%;
    height: 15%;
    margin-top: -18px;
}
.sizeBlockTicket{
    width:100%;
    //margin-left:2%;
    //padding: 2%;
    height: 15%;
    //margin-top:-20px;
}

.cartBottom{
    display: flex;
    flex-direction: column-reverse;
}

.productTitlePage{
    @extend .font-size-12;
    background-color: #F4F4F6;
    color:#5C5D5E;
    display: flex;
    padding: 5px;
    margin-bottom: 5%;
    align-items: center;
    font-family: 'Raleway';
}

.detailProduct{
    margin-top:20px;
}

.headerTicketProduct{
    color : #FFF;
    background: $gray-light 0% 0% no-repeat padding-box;
}

.table-ticket-price {
    border-collapse:separate;
    border:solid #6c757d 1px;
    border-radius:25px;
    th:first-of-type {
        border-top-left-radius: 22px;
    }
    th:last-of-type {
        border-top-right-radius: 22px;
    }
    tr:last-of-type td:first-of-type {
        border-bottom-left-radius: 22px;
    }
    tr:last-of-type td:last-of-type {
        border-bottom-right-radius: 22px;
    }
}

.imgPaiement {
    width: 100%;
    height: 25%;
    display: flex;

    .imgSubContent{
        background: transparent url("../images/moyenPaiement/sub.png") no-repeat;
        width:25%;
        //height:20%;
        background-size: 100%;
        margin: 0px;
        padding-bottom: 2%;
    }  

    .imgGiftCard{
        background: transparent url("../images/moyenPaiement/giftCard.png") no-repeat;
        width:25%;
        //height:20%;
        background-size: 100%;
        margin: 0px;       
    }
}


/* Tooltip container */
 .tooltip {
     position: relative;
     display: inline-block;
//     border-bottom: 1px dotted black;
}

  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
  
    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
  
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  /* Tooltip arrow */
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  } 
.tooltip-inner {
    width:350px!important;
    max-width:350px!important;
    max-height:350px!important;
    overflow-y:scroll
}

.image-product{
    max-width: 100%;
}

.planTicket{    
    position: fixed !important;
}
.btn_plan{
    margin-left:auto !important;
    margin-right:auto !important;
    display: block;
}
