.searchBar-formGroup {
    background-color: #98be58;
    height: 61px;
    text-align: center;
    color: white;
    font: normal normal normal 20px/20px Raleway;
    z-index: 1;
    position: relative;
    
    @include reducbox-media--sm{
        margin-top: 0%;
    }

    .form-control:active,
    .form-control:focus,
    .form-control:focus:active {
        background-color: #98be58;
        color: white;
        width: 90%;
    }
    ::placeholder {
        color: white;
        opacity: 1; /* Firefox */
      }

    .inputSearchBar {
        background-color: #98be58;
        color: white;
        font: normal normal normal 20px/20px Raleway;
        text-align: center;
        margin-left: 20px;
        width: 85%;
        height: 58px;
    }
    .searchBarImg {
        margin-top: -9px;
        margin-left: -29px;
    }
}

//// show product square in shop pages ////
.discount {
    position: absolute;
    right: -20px;
    top: -18px;
    align-items: center;
    background-color: #c53f37;
    display: flex;
    font: normal normal 700 22px/21px Roboto;
    justify-content: center;
    min-width: 49px;
    padding: 3px;
    width: 50%;
    height: 60px;
    border-radius: 83% 17% 67% 33% / 78% 39% 61% 22%;
    text-align: center;
    color: white;
    @media (min-width : 1200px) {
        width: 37%;
    }
}

.categoryContent {
    margin-bottom: 20px;

    .categoryTitle {
        font: normal normal bold 45px/45px Raleway;
        text-align: center;
    }

    .productPrice {
        padding: 3px;
        min-width: 78px;
        top: -28px;
        position: absolute;
        color: black;
    }

    .containerProductBlock{
        border: 4px solid $gray-light;
        border-radius: 15px;
        position:relative;
        min-height: 180px;
        @media (min-width : 768px) {
            min-height: 192px;
        }
        @media (min-width : 1270px) {
            min-height: 240px;
        }
    }

    .imgBlock{
        height: 150px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin: 25px;
    }

    .productBlock {
        background-color: $gray-light;
        border-radius: 11px;
        color: white;
        text-align: center;
        padding-bottom: 5px;
        width: 100%;

    }
    @include reducbox-media--sm {
        height: 60%;
    }

   
    .square-seemore-product {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        text-decoration: none;
        position: relative;
        display: block;

    }
    .overlay-seemore {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #ffffff96;
        border-radius: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'fuzzy';
        font-size: 28px;
        text-align: center;
        padding: 8px;
        @media (min-width: 1200px){
            font-size: 32px;
            border-radius: 11px;
        }
        @media (min-width: 1400px){
            font-size: 38px;
        }
    }

    .productPartner {
        font: normal normal 900 10px/10px Raleway;
        margin-bottom: 2%;
    }

    .productTitle {
        font: normal normal normal 16px/16px Raleway;        
        margin: 4%;
    }
 
 }

// speciale class for product square in shop homepage
.category-product-top{
    position: relative;
    height: 245px;
    margin-left: 0;
    padding-top: 20px;
    scrollbar-color: $primary white;
    width: 94%;
    margin: auto;
    @media (min-width: 768px){
        height: 245px;
    }
    @media (min-width: 1270px){
        height: 310px;
    }
}

.category-product-top::-webkit-scrollbar {
    -webkit-appearance: none;
}

.category-product-top::-webkit-scrollbar:horizontal {
    height: 18px;
}

.category-product-top::-webkit-scrollbar-thumb {
    border-radius: 15px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: $primary;
}

.square-product-web{
    width: 21%;
    height: 200px;
    position: absolute;
    @media (min-width: 768px){
        height: 200px;
    }
    @media (min-width: 1270px){
        height: 267px;
    }
}

.filter-products-wrapper {
    position: relative;
    display: inline;
}

.filter-products-wrapper::before {
    content: "";
    position: absolute;
    background-image: url(/images/static/filter-icon.svg);
    background-repeat: no-repeat;
    background-size: 2.2em auto; /* Increased icon size */
    background-position: 0.8em center;
    z-index: 2;
    width: 60px;
    height: 36px;
    transform: translateY(-50%);
    top: 50%;
}

.filter-mobile.filter-products-wrapper::before {
    background-size: 4em auto; /* Increased icon size */
    background-position: 1em;
    width: 90px;
    height: 90px;
}

.filter-products-wrapper::after {
    content: "";
    position: absolute;
    background-image: url(/images/static/chevron-up.svg);
    background-repeat: no-repeat;
    background-size: 1.6em auto; /* Increased icon size */
    background-position: right;
    z-index: 2;
    width: 30px;
    height: 16px;
    right: 15px;
    transform: translateY(-50%);
    top: 50%;
}

.filter-mobile.filter-products-wrapper::after {
    background-size: 4em auto; /* Increased icon size */
    background-position: 1em;
    width: 90px;
    height: 30px;
}

.filter-products {
    position: relative;
    padding: 0.4em 2.5em 0.4em 3.2em;
    font: normal normal normal 20px/40px Raleway;
    color: #000;
    background-color: #fff;
    border: 2px solid #adadab !important;
}

select.filter-products {
    /* reset */
    -webkit-appearance: none;
    -moz-appearance: none;
}
