//// module my account mobile  ////

.mobile.banner-menu {
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
    background-color: #85a7d4;
    color: white;
    transition: display 1s ease;
}

.dropdown-banner-menu{
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #85a7d4;
  color: white;
  text-align: center;
  z-index: 10;
}

///// my orders mobile
.mobile{
  .container-order{
    background-color: #efefef;
    padding: 30px;
    border-left: 10px solid gray;
    margin-bottom: 30px;
  }
  .pdfLogo {
    height: 130px !important;
  }
}

//////// refunds list mobile
.container-refund{
  border: 1px solid #bebebe;
  padding: 30px;
  margin-bottom: 30px;
}
.container-status0{
  border-left: 10px solid #FF0000;
}
.container-status1{
  border-left: 10px solid #5EA6DB;
}
.container-status2{
  border-left: 10px solid #ECC648;
}
.container-status3{
  border-left: 10px solid #98BE58;
}


////////// module Cagnotte ////////////////
////// web
.cagnotte-frame{
  border-radius: 20px;
  .cagnotte-amount{
    background: #3b4957;
    color: #FFF;
    padding: 10px;
    width: 35%;
    border-radius: 10px;
  }
  .kiosquePrice{
    border-radius: 10px;
  }
  .titleDescriptionPriceLabel{
    font: normal normal 500 12px/16px Raleway;
    margin:0;
  }
  .priceDecription{
    font: normal normal bold 33px/35px Ubuntu;
    font-size: 2vw;
    margin:0;
  }
}
////// mobile
.frame-balance{
  background-color: #4f5d6a;
  color: white;
  padding: 30px;
  text-align: center;
}
.container-history-grant{
  margin-bottom: 30px;
  padding: 30px;
  background-color: white;
}
.color-credit{
  border-left: 10px solid green;
  .color-amount{
    color: green;
  }
}
.color-debit{
  border-left: 10px solid orange;
  .color-amount{
    color: orange;
  }
}
.container-giftcard{
  background-color: #ececec;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 5px 7px 11px 0 #00000052;
  margin-bottom: 40px;
}
/// attribution giftcard web
.btn-attribution-gc, .big-btn-plan{
  border: 1px solid lightslategray;
  border-radius: 15px;
  padding: 25px 20px;
  height: 100%;
  cursor: pointer;
  &.active{
    background-color: $primary;
  }
}
.btn-attribution-gc:hover{
  background-color: $primary;
}

//////// module message push ////////////////
.btn-push-message, .big-btn-plan{
  border: 1px solid lightslategray;
  border-radius: 8px;
  padding: 15px 35px;
  height: 100%;
  cursor: pointer;
  &.active{
    background-color: $primary;
  }
}
.btn-push-message:hover{
  background-color: $primary;
}

.input-radio-big-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

//////// module trombinoscope ////////////////
/// mobile
.photo-trombi{
  border-radius: 50px;
  box-shadow: 7px 10px 12px 0 #8080808f;
}
//// web
.photo-trombi-web{
  border-radius: 20px;
  box-shadow: 7px 10px 12px 0 #8080808f;
}

//// module communication documents
.container-icon-edit-doc{
  position: absolute;
  top: 3px;
  right: 3px;
  padding: 3px;
  background-color: #ffffffc4;
  .editLogo, .trashLogo{
    width: 20px;
    height: 20px;
    background-size: cover;
    border: 0;
  }
  .trashLogo{
    background: transparent url("../images/common/trash_logo.svg") no-repeat center;
  }
  .editLogo{
    background: transparent url("../images/common/edit_logo.svg") no-repeat center;
  }
}


////////// module attribution gift card ////////////////
.attribution-gift-card{
  .users-list{
    height: 425px;
    overflow: auto;
  }
  .li-user:hover{
    background-color: #98BE58;
  }
  .frame-same-amount {
    padding: 10px;
    border: 1px solid #778799;
    border-radius: 15px;
    margin-top: 10px;
  }

}
