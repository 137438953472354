.background {
    background-color: #98be58;
    /*padding-top: 15px;
    height: 103px;*/
}

.header-logo {
  position: absolute;
  top:0;
  width: auto;
  background-color: white;
  border-radius: 0 13% 67% 33% / 78% 39% 61% 22%;;
  padding: 0 35px 40px 12px;
}

.header-welcome{
  margin-left:290px;
}

.header-shape-wave{
  width: 100%;
  position:absolute;
  top: 99%;
  z-index: -1;
}

.btn-header{
  height: 50px;
  border-radius: 25px;
  border : solid 1px white;
  background-color: $gray-light ;
}

/************* main menu *****************/
.main-menu{
  position: absolute;
  bottom: 18px;
  left: 290px;
}
.navbar,
.navbar-inverse {
    border-radius: 0;
    border: none;
    margin-bottom: 0;
    min-height: 80px;
    z-index: 11;
    padding: auto;
}

.nav li {
    display: inline;
    color: white;
}
.menu{
  position: relative;
}
.menu:hover{
  text-decoration: none;
}

.navbar-inverse .navbar-nav > li > a {
    color: #ffffff;
    font-family: Lato;
    font-size: 1.7em;
    font-weight: 300;
    padding: 30px 25px 33px 25px;
}
.item-menu a {
  color:white;
  font-size: 17px;
  padding: 0 5px;
  transition: all .3s ease 0s;
}

.menu-underline, .category-plus-underline{
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0) scale(0.6);
  width: auto;
  height: 10px;
  opacity: 0;
  visibility: hidden;
  //display: none;
  transition-property: opacity, transform;
  transition-duration: .3s;
  transition-timing-function: ease;
}

.item-menu:hover .menu-underline, .category-plus:hover .category-plus-underline{
  text-decoration: none;
  opacity: 1;
  visibility: visible;
  //display:initial;
  transform: translate(-50%, 0) scale(1);
}
.submenu {
  display: none;
  font-size: 17px;
  padding: 0 10px 10px 10px;
  position: absolute;
  top:calc(100% + 10px);
  left:50%;
  border-radius: 0 0 8px 8px;
  transform: translate(-50%, 0);
  text-align: center;
  transition: all .3s ease 0s;
}
.submenu ul, .submenu-plus ul{
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
.submenu a, .submenu-plus a{
  color:white;
}
.submenu-transparent{
  height: 15px;
  width: 100%;
  background-color: transparent;
}
.submenu-list{
  border-radius: 0 0 8px 8px;
}

.submenu-plus{
  display: none;
  font-size: 17px;
  position: absolute;
  top: 0;
  left: calc(100% + 7px);
  text-align: center;
  transition: all .3s ease 0s;
  border-radius: 8px;
  padding: 7px 0;

  .submenu-list{
    border-radius: 8px;
  }
}

/******************************/

.btn-help{
  border: 1px solid white;
  border-radius: 50%;
  font-size:16px;
  width: 50px;
  height: 50px;
  padding: 0;
}
.reducboxLogo {
    background: transparent url("../images/common/logo.png") no-repeat center;
    background-size: contain;
    width: 230px;
    height: 110px;
    border: 0;
    content: "";
}

.reducboxFooterLogo {
    //background: transparent url("../images/common/reducboxFooterLogo.svg") no-repeat center;
    background: transparent url("../images/common/reducboxFooterLogo.png") no-repeat center;
    background-size: contain;
    width: 255px;
    height: 110px;
    border: 0;
    content: "";
}

.exitLogo {
    background: transparent url("../images/common/exit.svg") no-repeat center;
    background-size: cover;
    width: 20px;
    height: 20px;
    border: 0;
}

.header-card {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 990px){
    .burgerNavLogo{
        top : 84px !important;
    }

    .burger-checkbox{
        top: 84px !important;
    }
        .burger-cart-checkbox{
        top: 84px !important;
    }
    .burgerAYDCartLink{
        top: 84px !important;
    }
    .burgerAYDNavLogo{
        top: 84px !important;
    }
}

@media screen and (max-width: 1000px) {
  .reducboxLogo {
    width: 150px;
    height: 60px;
  }
  .main-menu{
    left:200px
  }
  .header-welcome{
    margin-left: 200px;
  }

}

/* ---------- Start Burger Menu ------------ */

.burger-menu-container {
    display: block;
    position: absolute;
    top: 20px;
    right: -62px;

    z-index: 3000;

    -webkit-user-select: none;
    user-select: none;


    .burger-menu-container > ul li {
        list-style-type: none;
    }
    
    .burger-checkbox
    {
      display: block;
      width: 125px;
      height: 56px;
      position: fixed;
      top: 91px;
      right: 250px;
    
      cursor: pointer;
    
      opacity: 0; /* hide this */
      z-index: 100; /* and place it over the hamburger */  
    
      -webkit-touch-callout: none; /* Safari Touch */
      -webkit-user-select: none;   /* Webkit */
      -moz-user-select: none;      /* Firefox */
      -ms-user-select: none;       /* Edge*/
       user-select: none;       /* Future-proof*/
    }
    
    .burger-cart-checkbox
    {
      display: block;
      width: 169px;
      height: 56px;
      position: fixed;
      top: 91px;
      right: 252px;
    
      cursor: pointer;
    
      opacity: 0; /* hide this */
      z-index: 100; /* and place it over the hamburger */  
    
      -webkit-touch-callout: none; /* Safari Touch */
      -webkit-user-select: none;   /* Webkit */
      -moz-user-select: none;      /* Firefox */
      -ms-user-select: none;       /* Edge*/
       user-select: none;       /* Future-proof*/
    }
    
    .burger-menu {
        display: block;
        position: fixed;
        width: 350px;
        padding: 20px;
        padding-top: 150px;
        right: 0px;
    
        background: $gray-light;
        list-style-type: none;
        -webkit-font-smoothing: antialiased;
        /* to stop flickering of text in safari */
    
        transform-origin: 0% 0%;
        -webkit-transform: translate(100%, 0); 
        -moz-transform: translate(100%, 0);
        -o-transform: translate(100%, 0);
        transform: translate(100%, 0);
    
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        height: calc(100% + 25px);
        top: 0;
        right: 0;
        overflow-y: auto;

        // ::-webkit-scrollbar{
        //     top:10px;
        //     bottom: 0px;
        // }
    
    }
    
    .burger-menu-ayd-position {
        /*margin: -77px 0 0 0;*/
    }
    
    .burger-menu-position {
        /*margin: -20px 0 0 0;*/
    }
    
    .burger-menu ul {
        padding-left: 0px;
        list-style-type: none;
    }
    
    .burgerNavLogo {
        background: transparent url("../images/common/burgerIcon.svg") no-repeat center;
        background-size: cover;
        display: block;
        width: 125px;
        height: 80px;
        border: 0;
        position: fixed;
        top: 83px;
        right: 242px;
        z-index: 50;
    }
    
    .burgerAYDNavLogo {
        background-size: cover;
        display: block;
        width: 186px;
        height: 80px;
        border: 0;
        position: fixed;
        top: 82px;
        right: 240px;
    
        z-index: 50;
    }
    
    .burgerAYDCartLink {
        background-size: cover;
        display: block;
        width: 54px;
        height: 57px;
        border: 0;
        position: fixed !important;
        top: 90px ;
        right: 300px;
    
        z-index: 200;
    }
    
    .burgerNavLogo, .burgerAYDNavLogo, .burger-checkbox, .burger-cart-checkbox, .burgerAYDCartLink {
        transform-origin: 0% 0%;
        -webkit-transform: translate(300px, 0); 
        -moz-transform: translate(300px, 0);
        -o-transform: translate(300px, 0);
        transform: translate(300px, 0);
    
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    }
    
    .burger-checkbox:checked ~ div, .burger-cart-checkbox:checked ~ div, .burger-checkbox:checked ~ button, .burger-cart-checkbox:checked ~ .burgerAYDNavLogo, .burger-checkbox:checked, .burger-cart-checkbox:checked, .burger-cart-checkbox:checked ~ .burgerAYDCartLink {
        transform: none;
    }
}
/* ---------- End Burger Menu ------------ */

/* ---------- Start Pop in Reservation ------------ */

.reservationPopIn {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 20px;
    left: 20px;
    min-width: 15%;
    min-height: auto;
    max-width: min-content;
    max-height: min-content;
    padding: 10px;
    background-color: #d7dbe0;
    border-radius: 1%;
    z-index: 300;
}

.popInLabel{
    text-align: center;
}

.popInCountDown {
    text-transform: full-width;
}

/* ---------- End Pop in Reservation ------------ */






/*toggle*/
// $black:#1E1E1E;
// $grey:#CCCCCC;
// $white:#FFFFFF;

   span.switcher {
      position: relative;
      
      width:250px;
      height:50px;
      border-radius:25px;
      margin:20px 0;

      input {
         appearance: none;
         position: relative;
         margin-top:-12px;
         width:300px;
         height:50px;
         border-radius:25px;
         border:1px solid #fff;
         outline:none;
         font-family: 'Oswald', sans-serif;
        @media (min-width: 2200px){
          width: 330px;
          height: 58px;
          border-radius: 34px
        }

         &:before, &:after {
            z-index:2;
            position: absolute;
            top:50%;
            transform:translateY(-50%);
            color:#fff;
         }
         &:before {
            content: 'ON';
            left:20px;
         }
         &:after {
            content: 'OFF';
            right:20px;
         }
      }
      label {
         z-index:1;
         position: absolute;
         top:10px;
         bottom:10px;         
         border-radius:20px;
      }

      &.switcher-1 {
         .sa{ 
            &:not(:checked){
                &:after {                    
                   content: "SUPERADMIN";
                   color:#fff;
                   transition: color .5s;
            }
        }
        &:checked{
                &:after {                    
                   content: "SUPERADMIN";
                   color:whitesmoke;
                   transition: color .5s;
                }
            }
        }
        
        .gest{ 

            &:not(:checked){
                &:after {                    
                   content: "GESTIONNAIRE";
                   color:#fff;
                   transition: color .5s;
            }
        }
        &:checked{
                &:after {                    
                   content: "GESTIONNAIRE";
                   color:whitesmoke;
                   transition: color .5s;
                }
            }
        }
        .commerc{

          &:not(:checked){
            &:after {
              content: "COMMERCIAL";
              color:#fff;
              transition: color .5s;
            }
          }
          &:checked{
            &:after {
              content: "COMMERCIAL";
              color:whitesmoke;
              transition: color .5s;
            }
          }
        }
         input {            
            transition:.25s -.1s;
            &:checked {
                background-color: $gray-light;
                margin-top: -10px;

               &:before {
                content: "AYANT DROIT";
                color:#fff;
                  transition: color .5s .2s;
               }

               &+label {
                    left: 150px;
                    /* right: 78px; */
                    top: -26px;
                    background: #98be58;
                    transition: left 0.5s, right 0.4s 0.2s;
                    width: 140px;
                    height: 30px;
                 @media (min-width: 2200px){
                   left: 161px;
                   width: 155px;
                 }
               }
            }
            &:not(:checked) {
               background: $gray-light;
               margin-top:-10px;
               transition: background .5s -.1s;
               &:before {
                content: "AYANT DROIT";
                  color:whitesmoke;
                  transition: color .5s;
               }

               &+label {
                left: 10px;
                top: -26px;
                background: #98be58;
                transition: left 0.5s, right 0.4s 0.2s;
                width: 140px;
                height: 30px;
                @media (min-width: 2200px){
                  width: 154px;
                }
               }
            }
         }
      }
      &.switcher-2 {
         overflow:hidden;
         input {
               
            transition:background-color 0s .5s;
            &:before {
            color:$black;
            }
            &:after {

            color:$white;
            }
            &:checked {
               background-color: $gray-light;
               margin-top:-10px;
               &+label {
                  background:$white;
                  
                  animation: turn-on .5s ease-out;
                  
                  @keyframes turn-on {
                     0% {
                        left:100%;
                     }
                     100% {
                        left:0%;
                     }
                  }
               }
            }
            &:not(:checked) {
               background:$black;
               margin-top:-10px;
               &+label {
                  background:$black;
                  
                  animation: turn-off .5s ease-out;
                  
                  @keyframes turn-off {
                     0% {
                        right:100%;
                     }
                     100% {
                        right:0%;
                     }
                  }
               }
            }
         }
         label {
            top:0px;
            width:200px;
            height:50px;
            border-radius:25px;
         }
      }
   }



.st0 {
    fill: #FFFFFF;
}
.st1 {
    enable-background: new;
}
.st2 {
    fill: #5D5E5F;
}
.st33 {
    fill: #C36E6C;
}
