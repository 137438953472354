#product {
    padding-top: 4rem;
    padding-bottom: 4rem;
    color: #1d1d1b;

    h1 {
        text-transform: uppercase;
        border-bottom: solid 1px #ccc;
    }
}

.productTitle{
    font: normal normal bold 40px/45px Raleway;
}
.productSubtitle{  
    font: italic normal 300 17px/45px Raleway;
}

.productDescription{
    text-align: left;
}

.productShows {
    font: normal normal bold 19px/19px Raleway;
    margin:0px;
    padding:0px;
}

.productShowsMobile{
    font: normal normal bold 30px/30px Raleway;
    margin:0px;
    padding:0px;
}

.lstproduct{
    margin-top:2%;
}

.declinaison-mobile{
    width: 75%;
    border: 5px solid $gray-light;
    border-radius: 55px;
    padding-top: 98px;
    text-align: center;
    margin: 53px 0;

    .discount {
        width: 30%;
        height: 110px;
        font: normal normal bold 50px/50px Roboto;
        .text-is-small{
            font-size: 28px;
        }
    }

    .hr-declination-mobile{
        width: 30%;
        border-top: 4px solid  $gray-light;
    }

    .form-declination{
        border-radius: 38px;
        margin-bottom: 31px;
    }

    .declinaisonTitle{
        font: normal normal 900 32px/45px Raleway;
    }
}

.infoDetailMobile{
    font: normal normal normal 12px/40px Raleway;
    border-radius: 45px;
    padding: 25px;
}

.sizeBlockTicket{
    width:100%;
    height: 15%;
}

.product-breadcrumb-mobile{
    font: normal normal 36px/36px Raleway;
}

.detailProduct{
    margin-top:20px;
}

.headerTicketProduct{
    color : #FFF;
    background: $gray-light 0% 0% no-repeat padding-box;
}

.product-details .mobile{
    font-size: 35px !important;
    font-family: Raleway !important;
    p, p span, ul li, ul li span, div span{
        font-size: 35px !important;
        text-align: justify !important;
    }
    div strong, p strong{
        font-size: 38px !important;
    }
}

.header-ticketprice-mobile{
    border-radius: 40px 40px 0 0;
    background-color: $gray-light;
}
.line-price-mobile {
    border-top: 1px solid gray;
    &:nth-child(odd) {
        background-color: #f5f5f5;
    }
}

.modalMobile{
    &#terms_of_use_modal{
        .modal-body {
            p, span, strong{
                font-size: 35px !important;
            }
        }
    }
    .modal-dialog{
        margin-top: 20%;
        max-width: 95%;
    }
    .modal-header{
        font-size: 2em;
    }
    .close{
        font-size: 5.5rem;
    }
    .modal-body{
        font-size: 2em;
    }
    .modal-title{
        font-size: 1.4em;
    }
    .modal-footer{
        font-size: 2em;
    }

}