html,
body {
    height: 100%;
}

.bold{
    text-shadow: 0 0 black;
}
.text-green-reducbox{
    color: $green;
}
body.app-mobile.login-page{
    padding-bottom: 260px;
}
///////  mobile version //////
#login.login-mobile {
    max-width: 90%;
    margin: auto;
}
.logo-mobile{
    width: 85%;
}
.border-bottom-light{
    border-bottom: 2px solid #343434;
}
.border-bottom-dark{
    border-bottom: 3px solid black;
}
// globale classes and css for mobile
.fs-mobile-p{
    font-size: 35px !important;
}
.fs-mobile-subtitle{
    font-size: 45px !important;
}
.fs-mobile-title{
    font-size: 50px !important;
}
.fs-mobile-bigtext{
    font-size: 70px !important;
}
.h1-mobile{
    font-size: 70px;
}
.mobile{
    .col{
        padding-right: 30px;
        padding-left: 30px;
    }
    .alert{
        font-size: 40px;
    }
    .btn{
        border-radius: 50px;
        font-size: 36px;
        padding: 16px 43px;
    }
    /// form elements
    label{
        font-size: 40px !important;
    }
    input, select{
        height: 90px !important;
        font-size: 40px !important;
    }
    .form-check-input{
        position: relative;
        width: 55px;
    }
    .custom-file {
        height: 75px;
    }
    .reducCheckbox + .reducCheckboxFill:before {
        width: 55px;
        height: 55px;
    }
    .form-group{
        margin-bottom: 30px;
    }
    .fa-user, .fa-lock, .fa-eye, .fa-eye-slash, .fa-icon {
        font-size: 3em;
    }
    .margin-left{
        margin-left: 1.5rem !important;
    }
    .text-p{
        font-size: 35px !important;
    }
    .text-subtitle{
        font-size: 45px !important;
    }
    .text-title{
        font-size: 50px !important;
    }
    .text-bigtext{
        font-size: 70px !important;
    }
    .invalid-feedback{
        font-size: 35px !important;
        padding-left: 5px;
        padding-right: 5px;
    }
}
// page family
.block-family{
    padding: 30px;
    border: 1px solid #B6B7B6;
    border-left: 12px solid #B6B7B6;
    border-radius: 5px;
    margin-bottom: 30px;
}
.block-conjoint{
    border: 1px solid #807E7E;
    border-left: 12px solid #807E7E;
}
////////////

.containerMobile{
    max-width: 100%;
    .accesHomePageBoutique {
        width: auto;
        flex-grow: 1;
        margin: 50px 0px 20px 0px;
        color: #feffff;
        background-color: #a46e99;
        padding: 3% 0% 3% 0%;

        .imgHomePageBoutique {
            color: white;
            //padding-top: 35px;
            padding-left: 55px;
            font: normal normal normal 30px/30px Raleway;
            width: 95%;
        }

        .imgArrow {
            background: transparent url("../images/common/arrow.svg") no-repeat right;
        }
    }

    .card-container-mobile {
        min-width: 33%;
        width: 100%;
        padding: 0px 30px 0 30px;
        .refundMobile {
            margin-top: 70px;
        }

        .card-title-mobile{
            color: white;
            text-transform: uppercase;
            font-size: xxx-large;
        }

        .card-value-mobile {
            text-align: left;
            color: white;
            text-transform: uppercase;
            font-size: 55pt;
        }
    }
}

.headingMobile {
    border-bottom: 1px solid #98be58;
    padding: 0 0 20px;
    margin: 0 0 15px;
}

#homepagefooter a,
#homepagefooter a:hover {
    margin-left: 0px;
    text-decoration: none;
    color: white;
}

.headerMobile {
    overflow: hidden;
    width: 100%;
}

.reducboxHeaderLogoMobile {
    background-image: url(../images/common/logo.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
    width: 100%;
    height: 195px;
}
.toggleMobile{
    background-color: #98be58;
}

.burgerMobile{
    font-size: 30px;
}

.burger-menu-container-mobile {
    display: block;
    position: absolute;
    z-index: 50;
    -webkit-user-select: none;
    user-select: none;
    
    .burgerAYDNavLogo, .burger-cart-checkbox,.burger-checkbox,.burgerNavLogo {
        margin: -50px 0 0 0;
    }

    .burger-menu {
        display: block;
        position: fixed;
        width: 70%;
        height: 100vh;
        min-height: 100vh;
        padding: 100px 0 0px 64px;
        right: 0px;
        background: #505c6a;
        list-style-type: none;
        -webkit-font-smoothing: antialiased;
        /* to stop flickering of text in safari */
        transform-origin: 0% 0%;
        -webkit-transform: translate(100%, 0); 
        -moz-transform: translate(100%, 0);
        -o-transform: translate(100%, 0);
        transform: translate(100%, 0);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    }

    .burger-menu-ayd-position {
        margin: -120px 0px 0 0;
        font-size: 35px;
        ul{
            list-style-type: none;
        }
    }

    li{
        font-size: 45px;
    }
    .diagonalLineLogoHeader{
        width: 35px;
        height: 55px;
    }

    .burgerAYDCartLink {
        background-size: cover;
        width: 95px;
        height: 95px;
        border: 0;
        position: fixed;
        top: 52px !important;
        right: 10px;
        z-index: 200;
        display: block;
    }

    .burger-checkbox
    {
      display: block;
      width: 200px;
      height: 100px;
      position: fixed;
      top: 80px;
      right: -60px;
      cursor: pointer;
      opacity: 0; /* hide this */
      z-index: 100; /* and place it over the hamburger */  
    
      -webkit-touch-callout: none; /* Safari Touch */
      -webkit-user-select: none;   /* Webkit */
      -moz-user-select: none;      /* Firefox */
      -ms-user-select: none;       /* Edge*/
       user-select: none;       /* Future-proof*/
    }
    
    .burger-cart-checkbox {
        display: block;
        width: 269px;
        height: 110px;
        position: fixed;
        top: 91px !important;
        right: -60px;
      cursor: pointer;
      opacity: 0; /* hide this */
      z-index: 100; /* and place it over the hamburger */

      -webkit-touch-callout: none; /* Safari Touch */
      -webkit-user-select: none;   /* Webkit */
      -moz-user-select: none;      /* Firefox */
      -ms-user-select: none;       /* Edge*/
       user-select: none;       /* Future-proof*/
    }

    .burger-menu-position {
        margin: -55px 0 0 0;
    }
    
    .burger-menu ul {
        padding-left: 0px;
    }
    
    .burgerNavLogo {
        background: transparent url("../images/common/burgerIcon.svg") no-repeat center;
        background-size: cover;
        display: block;
        width: 300px;
        height: 100px;
        border: 0;
        position: fixed;
        top: 80px;
        right: -150px;
        z-index: 50;
    }
    
    .burgerAYDNavLogo {
        background-size: cover;
        display: block;
        width: 348px;
        height: 351px;
        border: 0;
        position: fixed;
        top: -20px !important;
        right: -106px;
        z-index: 50;
    }
    
    .burger-checkbox:checked ~ div, .burger-cart-checkbox:checked ~ div, .burger-checkbox:checked ~ button, .burger-cart-checkbox:checked ~ .burgerAYDNavLogo, .burger-checkbox:checked, .burger-cart-checkbox:checked, .burger-cart-checkbox:checked ~ .burgerAYDCartLink {
        transform: none;
    }
}


.menu-burger-search-container{
    .menu-burger-search{
        width: 240px;
        height: 135px;
        background: transparent url("../images/common/background-menu-burger-left.svg") no-repeat center;
        background-size: cover;
        display: block;
        position: fixed;
        top: 38px;
        left: -22px;
        z-index: 50;
    }
    .menu-burger-icon{
        margin-top: 23px;
        width: 75px;
        height: 75px;
        filter : invert(1);
        background-size: cover;
    }
    .icon-help{
        background: transparent url("../images/common/circle-question-regular.svg") no-repeat center;
        margin-left: 36px;
    }
    .icon-search{
        background: transparent url("../images/common/loupe.svg") no-repeat center;
        margin-left: 17px;
    }
    .menu-search{
        display: block;
        position: fixed;
        width: 100%;
        height: 325px;
        z-index: 20;
        padding: 150px 30px 100px 30px;
        right: 100%;
        top: 45px;
        background: #505c6a;
        -webkit-font-smoothing: antialiased;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    }

    #menu_search
    {
        // place the checkbox over the icon search
        position: fixed;
        top: 61px;
        left: 111px;
        width: 75px;
        height: 75px;
        display: block;
        opacity: 0; /* hide this */
        z-index: 100; /* and place it over the icon */

    }
    #menu_search:checked ~ .menu-search {
        -webkit-transform: translate(100%, 0);
        -moz-transform: translate(100%, 0);
        -o-transform: translate(100%, 0);
        transform: translate(100%, 0);
    }

}

.menu-burger-container{
    .menu-burger-right{
        width: 255px;
        height: 135px;
        background: transparent url("../images/common/background-menu-burger-right.svg") no-repeat center;
        background-size: cover;
        display: block;
        position: fixed;
        top: 38px;
        right: -33px;
        z-index: 50;
    }
    .icon-menu{
        background: transparent url("../images/common/icon-burger.svg") no-repeat center;
        margin-left: 36px;
    }
    .icon-cart-menu{
        background: transparent url("../images/common/icon-cart.svg") no-repeat center;
        margin-left: 17px;
    }
    .menu-burger-icon-right{
        margin-top: 23px;
        width: 75px;
        height: 75px;
        background-size: contain;
    }
    .menu_burger_checkbox{
        display: block;
        position: fixed;
        width: 100%;
        z-index: 20;
        padding: 150px 30px 100px 30px;
        left: 100%;
        background: #505c6a;
        -webkit-font-smoothing: antialiased;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    }

    #menu_burger_checkbox
    {
        // place the checkbox over the icon menu
        position: fixed;
        top: 61px;
        right: 111px;
        width: 75px;
        height: 75px;
        display: block;
        opacity: 0; /* hide this */
        z-index: 100; /* and place it over the icon */

    }
    #menu_burger_checkbox:checked ~ .menu_burger_checkbox {
        -webkit-transform: translate(-70%, 0);
        -moz-transform: translate(-70%, 0);
        -o-transform: translate(-70%, 0);
        transform: translate(-70%, 0);
    }
    .icon-cart-menu::after{
        content: "+";
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: white;
        width: 38px;
        font-size: 30px;
        margin-left: 23px;
    }
}

