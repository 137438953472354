body {
  padding-top: 190px;
  color: #333;
  font-family: "Roboto", sans-serif;
  font-size:  16px;
  @media (min-width: 2200px){
    font-size: 20px;
  }
}

a {
  color: #333;
}

.link-disabled {
  pointer-events: none;
  filter: opacity(50%);
}

.app {
  max-width: 1200px;
}

.fuzzy{
  font-family: "fuzzy",sans-serif;
}

.no-border {
  border: 0;
  box-shadow: none;
}

.table td {
  vertical-align: middle !important;
}

.badge {
  font-size: 100%;
  line-height: 1.2;
}

.filter-option {
  min-height: 24px;
}

.bg-black {
  background-color: black;
}

.bg-danger-light{
  background-color: #ffcace !important;
}

.bg-warning-light{
  background-color: #ffe080 !important;
}

.bg-white {
  background-color: white;
}

.bg-green {
  background-color: $primary;
}

.bg-grey {
  background-color: #eeeeee;
}

.bg-orange {
  background-color: #f25c05;
}

.bg-primary {
  background-color: $primary;
}

.table-striped-two-rows tr:nth-child(4n+1) td, .yourTableClass tr:nth-child(4n+2) td {
  background: white;
}

.marg-top-2 {
  margin-top: 2px !important;
}

.marg-top-15 {
  margin-top: 15px;
}

.tracking-tighter {
  letter-spacing: -0.05em;
}

.tracking-tight {
  letter-spacing: -0.025em;
}

.tracking-normal {
  letter-spacing: 0;
}

.tracking-wide {
  letter-spacing: 0.025em;
}

.tracking-wider {
  letter-spacing: 0.05em;
}

.tracking-widest {
  letter-spacing: 0.1em;
}

.border-green {
  border: 1px solid $primary;
}

.border-bottom-grey {
  border-bottom: 1px solid #cccccc;
}

.bordered-grey {
  border: 1px solid #cccccc;
}

.border-warning {
  border: 2px solid $warning;
}
.border-warning{
    border: 2px solid $warning;
}
.border-warning{
    border: 2px solid $warning;
}

.title {
  text-transform: uppercase;
  font-weight: 500;
}

.text-is-grey {
  color: #1d1d1b;
}

.text-is-red {
  color: #ff3f00;
}

.text-is-green {
  color: $primary;
}

.text-is-strike,
.striked {
  text-decoration: line-through;
}

.font-size-12 {
  font-size: 12px;
  @media (min-width: 1700px){
    font-size: 16px;
  }
  @media (min-width: 2200px){
    font-size: 20px;
  }
}

.text-is-small {
  font-size: 14px;
  font-weight: 300;
}

.text-is-medium {
  font-size: 15px;
  font-weight: 400;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.text-is-very-small {
  font-size: 12px;
  font-weight: 300;
}

.text-is-title {
  font-size: 25px;
  font-weight: 400;
}

.text-underline {
  text-decoration: underline;
}

.bg-white-become-grey-on-mouseover {
  background-color: white;
}

.bg-white-become-grey-on-mouseover:hover {
  background-color: #eeeeee;
}

/* breadcrumb */
.breadcrumb-backoffice {
    .breadcrumb-pointer {
        border-radius: 50%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        padding: 10px;
        width: 60px;
        height: 60px;
    }
    .breadcrumb-line{
        height: 2px;
        width: 120px;
        background-color: black;
    }
    .breadcrumb-label{
        position: absolute;
        top: 100%;
        margin-top: 0;
    }
    .current-breadcrumb-step {
        color: black;
        .breadcrumb-pointer{
            border: solid 2px black;
        }
    }

    .light-breadcrumb-step {
        color: #8a8a8a;
        .breadcrumb-pointer{
            border: solid 2px #8a8a8a;
        }
    }
}
/* breadcrumb mobile */
.breadcrumb-backoffice.mobile{
    .breadcrumb-pointer {
      width: 90px;
      height: 90px;
    }
    .breadcrumb-line{
      height: 4px;
      width: 150px;
    }
    .current-breadcrumb-step {
      .breadcrumb-pointer{
        border: solid 4px black;
      }
    }

    .light-breadcrumb-step {
      .breadcrumb-pointer{
        border: solid 4px #8a8a8a;
      }
    }
}


  /********/

h1.with-lines {
  margin: 2.5rem 0;
  display: table;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;

  &:before,
  &:after {
    border-top: 1px solid $primary;
    content: "";
    display: table-cell;
    position: relative;
    top: 0.5em;
    width: 45%;
  }

  &:before {
    right: 1.5%;
  }

  &:after {
    left: 1.5%;
  }
}

.link {
  color: $primary;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: gray;
  }
}

.relative {
  position: relative;
}

.squared {
  border-radius: 0;
}

.bg-grey-light {
  background-color: #f4f4f6;
}

.hr-bold {
  border-top: 2px solid gray;
}

.hr-dark {
  border-top: 2px solid black;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/** footer */

footer{
  position: relative;
  margin-top: 70px;
}

.footer-shape-wave{
  width: 100%;
  position:absolute;
  bottom: 99%;
}

#garanties {
  overflow: hidden;
  color: white;
  background: #505c6a;

  .slogan {
    line-height: 23px;
    font-size: 20px;
    font-weight: 300;
  }

  .heading {
    font-size: 20px;
    font-weight: 300;
  }

  .text-with-icon {
    position: relative;
    display: inline-block;
    color: #fff;
    vertical-align: top;
    padding: 0 0 0 72px;
  }

  .subtitle {
    display: block;
    margin: 0 0 9px;
    text-transform: uppercase;
    font-weight: 500;
  }

  .subtitle1 {
    line-height: 16px;
    font-size: 13px;
  }

  .subtitle2 {
    line-height: 19px;
    font-size: 15px;
  }

  p {
    line-height: 16px;
    font-size: 13px;
    font-weight: 500;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    line-height: 17px;
    font-size: 14px;
    font-weight: 300;
  }

  a {
    text-decoration: none;
    color: #fff;
  }
}

.paiement {
  li {
    float: left;
    margin: 0 2px;
    width: 34px;
    height: 23px;
  }
}

/* small icons displayed on the left */
.livraison::before {
  content: "";
  background: url(../images/common/sprite.png) no-repeat -95px -14px;
  position: absolute;
  left: 5px;
  top: 0;
  right: inherit;
  bottom: inherit;
  width: 53px;
  height: 42px;
}

.paiement::before {
  content: "";
  background: url(../images/common/sprite.png) no-repeat -167px -14px;
  position: absolute;
  left: 5px;
  top: 0;
  right: inherit;
  bottom: inherit;
  width: 30px;
  height: 42px;
}

.accelerateur::before {
  content: "";
  background: url(../images/common/sprite.png) no-repeat -276px -14px;
  position: absolute;
  left: 5px;
  top: 0;
  right: inherit;
  bottom: inherit;
  width: 42px;
  height: 42px;
}

.domicile::before {
  content: "";
  background: url(../images/common/sprite.png) no-repeat -214px -15px;
  position: absolute;
  left: 5px;
  top: 0;
  right: inherit;
  bottom: inherit;
  width: 39px;
  height: 42px;
}

.phone::before{
  content: "";
  background: url(../images/common/phone.svg);
  background-repeat: no-repeat;
  position: absolute;
  left: 5px;
  top: -4px;
  right: inherit;
  bottom: inherit;
  width: 30px;
  height: 30px;
}

a.active,
a.nav-link.active {
  color: $primary !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #dbdbdb;
}

.bannerEcardGrant {
  width: 100%;
  height: 20%;
  background-color: #85a7d4;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
}

.bannerFirstConnection {
  width: 100%;
  height: 20%;
  background-color: #85a7d4;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
}

.buttonBannerEcardGrant {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;
  width: 10%;
  display: flex;
}

.bannerTitles {
  display: flex;
  flex-direction: column;
  margin: 10px;
  justify-content: center;
}

.bannerEcardGrant .eCardsGrantsLogo {
  position: absolute;
  top: -10%;
  left: 50%;
  background: transparent url("../images/eCardsGrants/bannerEcardGrant.png") no-repeat center;
}

.bannerBigTitle {
  color: white;
  text-transform: uppercase;
  font-size: xx-large;
  margin-bottom: 0px;
  text-align: center;
  @media only screen and (max-width: 1224px) {
    margin-top: 10px;
  }
}

.bannerNavBar {
  display: flex;
  justify-content: center;
}

.bannerMediumTitle {
  color: white;
  font-size: medium;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.bannerMediumTitleActive {
  color: white;
  font-size: medium;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #3b4957;
  display: flex;
  align-items: center;
}

.titleForm {
  color: black;
  text-transform: uppercase;
  font-size: larger;
  font-weight: 1000;
}

.leftArrow {
  background: transparent url("../images/common/left_arrow.svg") no-repeat center;
  width: 100%;
  height: 100%;
  margin: 0.5%;
}

.pdfLogo {
  background: transparent url("../images/common/pdf_logo.svg") no-repeat center;
  background-size: contain;
  width: 100%;
  min-width: 40px;
  height: 60px;
  outline: none;
  border: 0;
}

.pdfLogo:active {
  outline: none;
  border: 0;
}

.validateLogo {
  background: transparent url("../images/common/validate_logo.png") no-repeat center;
  background-size: cover;
  width: 30px;
  height: 30px;
}

.bigValidateLogo {
  background: transparent url("../images/common/validate_logo.png") no-repeat center;
  background-size: cover;
  width: 300px;
  height: 300px;
  border: 0;
}

.cancelLogo {
  background: transparent url("../images/common/cancel_logo.png") no-repeat center;
  background-size: cover;
  width: 30px;
  height: 30px;
}

.bigCancelLogo {
  background: transparent url("../images/common/cancel_logo.png") no-repeat center;
  background-size: cover;
  width: 300px;
  height: 300px;
  border: 0;
}

.bigCanceledLogo {
  background: transparent url("../images/common/canceled_logo.png") no-repeat center;
  background-size: cover;
  width: 200px;
  height: 200px;
  border: 0;
}

.diagonalLineLogo {
  background: transparent url("../images/common/diagonal-line.svg") no-repeat center;
  background-size: cover;
  width: 5px;
  height: 10px;
  border: 0;
  filter: invert(1);
}

.diagonalLineLogoHeader {
  background: transparent url("../images/common/diagonal-line.svg") no-repeat center;
  background-size: cover;
  margin-right: 5px;
  width: 10px;
  height: 15px;
  border: 0;
  filter: invert(1);
}

.cartLogo {
  background: transparent url("../images/common/panier.svg") no-repeat center;
  background-size: cover;
  width: 25px;
  height: 25px;
  margin-left: 8px;
}

.cartLogo::after {
  content: "+";
  height: 0px;
  width: 5px;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-family: courier;
  color: white;
  padding-bottom: 8px;
}

.cartBlock {
  background-color: #a4c127;
  margin-top: 5px;
  width: 45px;
  height: 30px;
}

.errorLogo {
  background: transparent url("../images/common/error.svg") no-repeat center;
  background-size: cover;
  width: 300px;
  height: 300px;
  border: 0;
}

.leftArrow:hover {
  cursor: pointer;
}

.btn-success {
  background-color: #98BE58FF;
  border-color: #98BE58FF;
}

.btn-outline-success {
  border-color: #98BE58FF;
  color: #98BE58FF;
}

.btn-success:hover,
.btn-success:focus,
.btn-outline-success:hover,
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled):active {
  background-color: #98BE58FF;
  border-color: #98BE58FF;
}

.btn-success:focus {
  box-shadow: 0 0 0 0.2rem rgb(152 190 87 / 50%);
}

.common-button {
  border-radius: 50px;
  background-color: $primary;
  color: white;
  min-width: 25%;
  display: block;
}
.common-button:hover {
  color: white;
}

.common-button-outline {
  color: $primary;
  border-color: $primary;
  border-radius: 50px;
}

.common-button-outline:hover {
  color: #fff;
  background-color: $primary;
  border-color: $primary;
}

.common-button-rounded {
  border-radius: 50px;
  min-width: 25%;
  display: block;
}

.custom-file-input ~ .custom-file-label::after {
  content: "Parcourir";
}

.secondary-button {
  border-radius: 50px;
  background-color: #3b4957;
  color: white;
  min-width: 25%;
  display: block;
}

.secondary-light-button {
  border-radius: 24px;
  background-color: #6d757d;
  color: white;
  min-width: 25%;
  display: block;
}

.danger-button {
  border-radius: 24px;
  background-color: #dc3545;
  color: white;
  min-width: 25%;
  display: block;
}

.cancel-button {
  border-radius: 24px;
  background-color: red;
  color: white;
  min-width: 25%;
  display: block;
}

.btn-w-auto {
  min-width: auto;
}

.warningLabel {
  background-color: red;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  margin: 10px;
  padding: 10px;
}

.warningPanel {
  margin: auto;
  align-items: center;
  justify-content: center;
  width: 75%;
}

.cseDoc {
  margin: 10px;
  min-width: 30%;
  max-width: 30%;
  margin-bottom: 20px;
}

.paginate-web {
  .page-link {
    z-index: 2 !important;
  }
}

.common-search-button {
  background: transparent url("../images/common/loupe.svg") no-repeat center;
  background-size: cover;
  width: 30px;
  height: 30px;
  border: 0;
}

.form-container {
  background-color: #f4f4f6;
  padding: 2%;
  justify-content: center;

  .TitleLink {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .containerPdf {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-self: stretch;
  }
}

.form-input-full {
  width: 100%;
  margin: 10px;
}

.form-label-right {
  width: 35%;
  display: block;
  text-align: right;
  margin-bottom: 0;
}

.form-label-right-40 {
  width: 40%;
}

.reducCheckbox {
  display: none;
}

.reducCheckboxFill {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.reducCheckbox + .reducCheckboxFill:before {
  width: 15px;
  height: 15px;
  border: 2px solid gray;
  border-radius: 2px;
  background: white;
  position: relative;
  display: inline-block;
  margin-right: 1ex;
  content: "";
}

.reducCheckbox:checked + .reducCheckboxFill:before {
  background: $primary;
}

.reducCheckboxMobile {
  width: 15px;
  height: 15px;
  border: 2px solid gray;
  border-radius: 2px;
  background: white;
  position: relative;
  display: inline-block;
  margin-right: 1ex;
  content: "";
}

.reducCheckboxMobile:checked {
  background: $primary;
}

.roundedEnum, .roundedEnumMobile {
  border: solid 2px black;
  background-color: #8a8a8a;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 30px;
  height: 30px;
}

.roundedEnumMobile {
  font-size: 30px;
  padding: 7px;
  width: 60px;
  height: 60px;
}

.containOfferExtraData {
  img {
    max-width: 100%;
  }
}
.containOfferExtraDataMobile {
    font-size: 30px;
}

.form-select {
  border: solid 1px #ced4db !important;
  .btn{
    background-color: white;
  }
}
.w-fix-select{
  .dropdown-menu{
    max-width: 100%;
  }
}

.c1-N {
  content: " ";
  width: 40px;
  height: 100%;
  display: block;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.c2-4-grant {
  width: 50%;
  margin: auto;
}

.c3-4-grant,
.c4-4-grant {
  width: 21%;
  margin: auto;
}

.c2-5,
.c3-5,
.c4-5 {
  width: 24%;
  margin: auto;
}

.c5-5 {
  width: 22%;
  margin: auto;
}

.c2-5-grant {
  width: 30%;
  margin: auto;
}

.c3-5-grant {
  width: 20%;
  margin: auto;
}

.c4-5-grant {
  width: 30%;
  margin: auto;
}

.c2-6,
.c4-6,
.c5-6 {
  width: 20%;
  margin: auto;
}

.c3-6 {
  width: 30%;
  margin: auto;
}

.c2-6-eCard {
  width: 40%;
  margin: auto;
}

.c5-6-eCard {
  width: 20%;
  margin: auto;
}

.c3-6-eCard,
.c4-6-eCard {
  width: 15%;
  margin: auto;
}

.c2-9-AY-DR {
  width: 30px;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.c3-9-AY-DR,
.c4-9-AY-DR,
.c6-9-AY-DR,
.c7-9-AY-DR,
.c8-9-AY-DR,
.c9-9-AY-DR {
  width: 11%;
  margin: auto;
}

.c5-9-AY-DR {
  width: 19%;
  margin: auto;
}

.c1-RE-MA {
  content: "";
  width: 10px;
  height: 100%;
  display: block;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.c2-10-RE-MA,
.c3-10-RE-MA {
  width: 10%;
  margin: auto;
}

.c4-10-RE-MA {
  width: 16%;
  margin: auto;
}

.c5-10-RE-MA,
.c6-10-RE-MA,
.c7-10-RE-MA {
  width: 12%;
  margin: auto;
}

.c8-10-RE-MA {
  width: 21%;
  margin: auto;
}

.c9-10-RE-MA {
  width: 3%;
  margin: auto;
}

.c3-11,
.c4-11,
.c7-11,
.c8-11,
.c10-11 {
  width: 10%;
  margin: auto;
}

.c5-11 {
  width: 16%;
  margin: auto;
}

.c2-11,
.c6-11,
.c9-11 {
  width: 8%;
  margin: auto;
}

.c3-13,
.c4-13,
.c5-13 {
  width: 9%;
  margin: auto;
}

.c2-13,
.c7-13,
.c8-13,
.c9-13,
.c10-13 {
  width: 6%;
  margin: auto;
}

.c6-13,
.c11-13,
.c12-13 {
  width: 7%;
  margin: auto;
}

.c5-13 {
  width: 20%;
  margin: auto;
}

// Last column take what's left
.cN-N {
  width: 5%;
  margin: auto;
}

.w-16 {
  width: 16%;
}

///// frame "de quoi s'agit-il ?" ////
.infoPanel {
  border: solid;
  border-width: 2px;
  border-color: #505c6a;
  background-color: #505c6a;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  height: min-content;
  margin: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 173px;
}

.infoPanelHeader {
  background: #505c6a;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: min-content;
  padding: 2%;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 25px 25px 0 0;
}

.dropdownInfoPanelHeader {
  padding: 1%;
  border-radius: 25px;
  text-transform: none;
  cursor: pointer;
  justify-content: start;
}

.infoPanelTopHeader {
  background-color: #505c6a;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: min-content;
  padding: 1%;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 25px 25px 0 0;
}

.infoPanelContent {
  background-color: white;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: min-content;
  padding: 6%;
}

.infoPanelBorder {
  border-radius: 0 0 28px 28px;
}

.infoTopPanelContent {
  background-color: white;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: min-content;
  padding: 2%;
}

.info-panel-green {
  border-color: $primary;
  background-color: $primary;

  .infoPanelHeader {
    background-color: $primary;
  }

}

.infoPanelLink {
  padding: 2%;
  background-color: white;
  border-radius: 0 0 28px 28px;
}

.infoTopPanelLink {
  background-color: white;
  border-radius: 0 0 28px 28px;
}

.infoPanelTop {
  border: solid;
  border-width: 2px;
  border-color: #505c6a;
  background-color: #505c6a;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  height: min-content;
  margin: 20px;
}

.transitionHeader {
  justify-content: center;
  align-items: center;
  width: 75%;
  height: 40px;
  margin-top: 80px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
}

///// mobile "de quoi s'agit-il ?"
.infoPanel-mobile {
  width: 100%;
  margin: 30px 0;
  border-radius: 60px;

  .infoPanelHeader {
    background-color: rgba(117, 190, 218, 0.0);
  }

  .infoPanelContent {
    border-radius: 0 0 60px 60px;
  }
}

/////////////
.min-width-65 {
  min-width: 65%;
}

.div-table {
  display: flex;
  flex-direction: column;
  margin: auto;
  border-spacing: 5px;
  /* cellspacing:poor IE support for  this */
}

.div-table-row {
  display: row;
  width: auto;
  clear: both;
  border: 1px solid;
  border-color: #a0a0a0;
  border-radius: 4px;
  margin: 5px;
}

.div-table-col-header {
  float: left;
  display: column;
  padding-left: 5px;
  border-left: 2px solid;
  border-color: #a0a0a0;
  text-transform: uppercase;
  color: #a0a0a0;
  font-size: 1vw;
}

.div-table-col {
  float: left;
  display: column;
  margin-left: 10px;
  align-items: center;
  overflow: hidden;
  //white-space: nowrap;
}

.status-line {
  border-radius: 3px;
  margin: -2px;
  height: 45px;
  width: 40px;
}

.status-ok {
  background-color: #5EA6DB;
}

.status-ko {
  background-color: red;
}

.status-blocked {
  background-color: #ec7a48;
}

.status-pending {
  background-color: #ecc648;
}

.status-reimbursed {
  background-color: #98BE58;
}

.status-finish {
  background-color: #98BE58;
}

.conjoint-color {
  background-color: #807E7E;
}

.enfant-color {
  background-color: #B6B7B6;
}

.cb {
  background: transparent url("../images/common/cb.svg") no-repeat center;
  background-size: cover;
  width: 40px;
  height: 30px;
  outline: 0px solid transparent;
}

.mastercard {
  background: transparent url("../images/common/mastercard.svg") no-repeat center;
  background-size: cover;
  width: 40px;
  height: 30px;
  outline: 0px solid transparent;
}

.visa {
  background: transparent url("../images/common/visa.svg") no-repeat center;
  background-size: cover;
  width: 40px;
  height: 30px;
  outline: 0px solid transparent;
}

.headerAdminTab {
  color: #fff;
  background: #3b4957 0% 0% no-repeat padding-box;
}

.sizeHearderTable {
  padding-top: 12px;
  padding-bottom: 12px;
  font: normal normal normal 16px Raleway;

  th {
    font-weight: normal;
  }
}

.labelPhoto {
  margin: auto 0;
}

.pointerMobile {
  width: 300px;
  height: 60px;
  background: #a46e99;
  position: relative;
  margin-left: 35px;
  color: white;
  line-height: 60px;
  text-align: center;
}

.pointerMobile:before {
  content: "";
  position: absolute;
  top: 0;
  left: -35px;
  width: 0;
  height: 0;
  border-top: 30px solid #a46e99;
  border-bottom: 30px solid #a46e99;
  border-left: 35px solid transparent;
}

.pointerMobile:after {
  content: "";
  position: absolute;
  left: 300px;
  bottom: 0;
  width: 0;
  height: 0;
  border-top: 30px solid transparent;

  border-bottom: 30px solid transparent;

  border-left: 35px solid #a46e99;
}

.pointerMobileSelected {
  width: 300px;
  height: 60px;
  background: #684574;
  position: relative;
  margin-left: 35px;
  color: white;
  line-height: 60px;
  text-align: center;
}

.pointerMobileSelected:before {
  content: "";
  position: absolute;
  top: 0;
  left: -35px;
  width: 0;
  height: 0;
  border-top: 30px solid #684574;
  border-bottom: 30px solid #684574;
  border-left: 35px solid transparent;
}

.pointerMobileSelected:after {
  content: "";
  position: absolute;
  left: 300px;
  bottom: 0;
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 35px solid #684574;
}

.label-size {
  color: #5b5b5b;
  margin-top: 0.2rem;
  font-size: 0.9em;
  font-style: italic;
}

.liste-ul-aide {
  font-size: 0.8em;
  color: #505c6a;
}

.li-marge {
  margin: 3px;
}

.max-100 {
  max-width: 100%;
}

@media only screen and (max-width: 1224px) {
  .change-width {
    //background: url("../images/gelule/Boutique.svg") no-repeat;
    width: 100% !important;
  }

  .change-font-size {
    //background: url("../images/gelule/Boutique.svg") no-repeat;
    font-size: 1vw !important;
  }

}
.login-left-section {
  overflow-y: auto;
  .text-left-section{
    background-color: rgba(89, 130, 109, 0.8); // #59826D;
    color: white;
  }

  .loginText {
    color: rgba(255, 255, 255, 0.8);
    font-size: large;
    margin-left: 3px;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .loginTextTitle {
    opacity: 1;
    font-size: xx-large;
    margin-left: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.icon {
  background-size: cover;
  width: 25px;
  height: 23px;
  padding: 7px;
  border: 1px solid black;
  border-radius: 30% / 40%;
}

.icon-plus {
  background: transparent url("../images/common/plus-solid.svg") no-repeat center;
}

.icon-minus {
  background: transparent url("../images/common/minus-solid.svg") no-repeat center;
}

.eCardsFlexPricesLogo {
  background: transparent url("../images/common/gift-card.png") no-repeat center;
  background-size: contain;
  width: 100%;
  min-width: 40px;
  height: 60px;
  outline: none;
  border: 0;
}


///*************************///
// spinner modal
.pop-up-spinner{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #00000091;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectModalBig{
  width: 247px !important;
}