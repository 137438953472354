.tr-details {
  display: none;
}

.card-order{
  background-color: transparent;
  border: none;
}

.card-body-order {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: none;
  border-radius: 10px;
}

.card-body-mobile {
  box-shadow: none;
  border-radius: 25px;
  padding: 15px 20px;
}

.card-text-order{
  font-size: 18px;
}