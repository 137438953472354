.wrapper{
    position:relative;
}
.tooltip {
    transform: none;
    opacity: 1.0;
    //border-bottom: none;
}
.borderTop{
  border-top: 1px dotted black; /* If you want dots under the hoverable text */
}
.tooltip:hover > .tooltip-text, .tooltip:hover > .wrapper {
    pointer-events: auto;
    opacity: 1.0;
}

.tooltip > .tooltip-text, .tooltip >.wrapper {
    display: block;
    position: absolute;
    z-index: 6000;
    overflow: visible;
    padding: 5px 8px;
    margin-top: 10px;
    line-height: 16px;
    border-radius: 4px;
    text-align: left;
    color: #fff;
    background: #000;
    pointer-events: none;
    opacity: 0.0;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    height: 200px;
    width: 465px;
    max-width: 100%;
}

/* Arrow */
.tooltip > .tooltip-text:before, .tooltip > .wrapper:before  {
    display: inline;
    top: -5px;
    content: "";
    position: absolute;
    border: solid;
    border-color: rgba(0, 0, 0, 1) transparent;
    border-width: 0 .5em .5em .5em;
    z-index: 6000;
    left: 20px;
}

/* Invisible area so you can hover over tooltip */
.tooltip > .tooltip-text:after, .tooltip > .wrapper:after  {
    top: -20px;
    content: " ";
    display: block;
    height: 20px;
    position: absolute;
    width: 60px;
    left: 20px;
}

.wrapper > .tooltip-text {
    overflow-y: auto;
    max-height: 190px;
    display: block;

}


/* custom css for tooltip and icon help */

.tooltip-help.tooltip .tooltip-inner {
    background-color: #323232FF !important;
    width: 250px !important;
    overflow-y: hidden;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #323232FF !important;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #323232FF !important;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #323232FF !important;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #323232FF !important;
}

.icon-help{
    background: transparent url("../images/common/circle-question-regular.svg") no-repeat center;
    background-size: contain;
    width: 17px;
    height: 17px;
    border: 0;
    margin-right:5px;
}

//// tooltip for mobile  ///
.tooltip-mobile.tooltip .tooltip-inner {
    width: 400px !important;
    font-size: 32px;
}
.mobile{
    .icon-help {
        width: 35px;
        height: 35px;
    }
}


//// admin to manage tooltip

.checkbox-deleted-tooltip{
    position: absolute;
    top: 10px;
    right: 15px;
}
