///// breadcrumb mobile et web /////
.breadcrumb-reducbox{
  .cart-pointer{
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    padding: 10px;
    width: 110px;
    height: 110px;
  }
  .current-cart-pointer{
    border: solid 5px black;
    color: black;
  }
  .light-cart-pointer{
    border: solid 5px #8a8a8a;
    color: #8a8a8a;
  }
  .extralight-cart-pointer{
    border: solid 5px #d9d9d9;
    color: #d9d9d9;
  }
  .cart-line{
    position: absolute;
    top:31%;
    width: 30%;
    height: 5px;
    background-color: black;
    z-index: -1;
  }
  .line1{
    left: 150px;
  }
  .line2{
    left: 50%;
  }
  .light-line{
    background-color: #8a8a8a;
  }
}

.breadcrumb-web{
  .cart-pointer{
    font-size: 35px;
    padding: 10px;
    width: 70px;
    height: 70px;
  }
  .current-cart-pointer{
    border: solid 3px black;
  }
  .light-cart-pointer{
    border: solid 3px #8a8a8a;
  }
  .extralight-cart-pointer{
    border: solid 3px #d9d9d9;
  }
  .cart-line{
    top:35%;
    height: 3px;
  }
  .line1{
    left: 210px;
  }

}

//////////// cart product list mobile ///
.cart-mobile{
  .cart-quantity {
    button{
      width: 100px;
      height: 100px;
      background-color: #e2e2e2;
      font-size: 50px;
      padding: 0 !important;
    }
    input{
      font-size: 50px;
      height: 100px !important;
    }
  }
}

//////////// edit page (step3)
.borders-total {
  border: 3px solid black;
  padding: 7px 10px;
  label{
    margin-bottom: 0;
  }
}

.green-frame {
  background-color: #98be59a8;
  border: 1px solid green;
  color: green;
  padding: 10px;
}

////////////
 /* HIDE RADIO */
.payment{
    [type=radio] { 
       position: absolute;
       opacity: 0;
       width: 0;
       height: 0;
       outline: 0px solid transparent;
     }
     
     /* IMAGE STYLES */
     [type=radio] + img {
       cursor: pointer;
       outline: 0px solid transparent;
     }
     
     /* CHECKED STYLES */
     [type=radio]:checked + img {
       outline: 2px solid #000;
     }

}

.totalCart {
  background-color: rgba(238,238,238,0.7);
}

.labelPrice {
  justify-content: left;  
  font-size: 16px;
  margin-right: 40px;
}
.labelPriceResult {
  justify-content: left;  
  font-size: 25px;
}

.labelCartRecapPaiement {
  width: 60%;
  margin-left: 20px;
}
.cartRecapTotal {
  background-color: rgba(238,238,238,0.7);
  width: 90%;
  margin-left: 25%;

  .labelPrice {
    justify-content: left !important; 
    font-size: 16px !important; 
    margin-right: 74px !important; 
  }

  .labelPriceTotal {
    justify-content: left !important; 
    font-size: 16px !important; 
    margin-right: 7px !important; 
  }
}

.economie {
  color: red;
}

.titlePaiement {
  height: 50px;
  color: #FFF;
  background: #3B4957 0% 0% no-repeat padding-box;
  font-family: "Roboto", sans-serif;
 
  font-weight: bold;
  
  .labelPaiement {
    margin-top: 13px;
    margin-left: 13px;
  }
}

.labelMoyenPaiment {
    max-width: 166px;
    padding-right: 0px;
  }

  .cartDetailArray{
    vertical-align: middle !important;
    margin:auto;
  }


///* mobile *///

.icon-cart{
  background-image: url("../images/common/panier.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 60px;
  height: 60px;
}
.icon-cart::after {
  content: "+";
  height: 0;
  width: 49px;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  padding-bottom: 20px;
  padding-left: 22px;
  margin-top: 18px;
}

  .cartLogoMobile {
    background: transparent url("../images/common/panier.svg") no-repeat center;
    background-size: cover;
    width: 60px;
    height: 60px;
    margin-left: 15px;
    margin-top: 5px;
  }
  
  .cartLogoMobile::after {
    content: "+";
    height: 0px;
    width: 15px;
    font-size: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-family: courier;
    color: white;
    padding-bottom: 20px;
    padding-left: 22px;
  }
  
  .cartBlockMobile {
    background-color: #a4c127;
    margin-top: 41px;
    margin-bottom: 20px;
    width: 100%;
    height: 100px;
    text-align: center;
    button{
      color: white;
      text-align: center;
    }
  }

  .cartMobile {
    label, .champObligatoire, td {
      font-size: 35px;
    }

    .btnMobile {
      font-size: 60px;
      width: 100%;
      margin-top: 30px;
    }

    .btnMobile70 {
      font-size: 70px;
      width: 80%;
      margin-top: 30px;
    }

    input {
      font-size: 35px;
    }

    .titleForm {
      font-size: 50px;
    }

    .cartAddressRecap {
      font-size: 30px;
    }

    .cartAddressRecapTitle {
      font-size: 40px;
    }


    .titlePaiement {
      height: 75px;
      color: #FFF;
      background: #3B4957 0% 0% no-repeat padding-box;
      font-family: "Roboto", sans-serif;
      font-weight: 400;

      .labelPaiement {
        margin-top: 13px;
        margin-left: 13px;
        font-size: 40px;
      }
    }

    .headerTicketProduct {
      height: 75px;
      color: #FFF;
      background: $gray-light 0% 0% no-repeat padding-box;
      font-family: "Roboto", sans-serif;
      font-weight: 100;
      font-size: 40px;
    }

    .labelPriceMobile, .labelPriceTotal {
      justify-content: left !important;
      font-size: 30px !important;
      margin-right: 74px !important;
    }

    .labelPriceMobileTotal {
      justify-content: left !important;
      font-size: 30px !important;
      margin-right: 7px !important;
    }

    .labelPriceMobileResult, #totalEconomies, #totalPrice, #resteAPayer, .labelPrice, .labelPriceResult {
      justify-content: left;
      font-size: 50px !important;
    }

    .cartRecapTotalMobile {
      background-color: rgba(238, 238, 238, 0.7);
    }
  }
////////////
  .icon-cart-web{
    background-image: url("../images/common/panier.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 20px;
    height: 20px;
  }
  .icon-cart-web::after {
    content: "+";
    height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
    padding-bottom: 20px;
    width: 6px;
    font-size: 11px;
    padding-left: 12px;
    margin-top: 6px;
  }
    .container-addtocart-button{
        margin: auto;
        width: 75%;
        color: white;
        button{
          color: white;
          text-align: center;
      }
    }

//////////

  .payment{
  margin: auto;
  }
  .cb {
    background: transparent url("../images/common/cb.svg") no-repeat center;
    background-size: cover;
    width: 90px;
    height: 80px;
    outline: 0px solid transparent;
  }

  .mastercard {
    background: transparent url("../images/common/mastercard.svg") no-repeat center;
    background-size: cover;
    width: 90px;
    height: 80px;
    outline: 0px solid transparent;
    margin-left: 20px;
  }

  .visa {
    background: transparent url("../images/common/visa.svg") no-repeat center;
    background-size: cover;
    width: 90px;
    height: 80px;
    outline: 0px solid transparent;
    margin-left: 20px;
  }



  .totalCartMobile {
  background-color: rgba(238, 238, 238, 0.7);
  /* margin-left: 6%; */
  margin: auto;
}

.image-cart{
  width: 150px;
  margin-right: 20px;
  max-width: 100%;
}

.bouton-cart{
  min-width: 43px !important;
}

.no-wrap-flex{
  flex-wrap: nowrap !important;
}

.applyButtonFrame{  
  width: 90%;
  margin-left: 25%;

  .applyButton {
    position: relative;
    float: right;
  }
}