.togglePositionMobile{
    margin: 20px 30% 0px;
}

.reservationPopInMobile {
  font-size: 35px;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 20px;
  left: 20px;
  min-height: auto;
  max-width: 50%;
  max-height: min-content;
  padding: 10px;
  background-color: #d7dbe0;
  border-radius: 1%;
  z-index: 300;
  .common-button{
    font-size: 38px;
    border-radius: 50px;
    padding: 20px 55px;
  }
}
