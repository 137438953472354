/// XXSmall devices. Below, and 360px
@mixin reducbox-media--xxs() {
    @media (max-width: $layout-breakpoint-xxs) {
      @content;
    }
  }
  
  /// XSmall devices. Below 600px
  @mixin reducbox-media--xs() {
    @media (max-width: $layout-breakpoint-xs - 1) {
      @content;
    }
  }
  
  /// Greater then small devices. Above, and 600px
  @mixin reducbox-media--gt-xs() {
    @media (min-width: $layout-breakpoint-xs) {
      @content;
    }
  }
  
  /// Between xsmall and small. (600px - 959px)
  @mixin reducbox-media--sm() {
    @media (min-width: $layout-breakpoint-xs) and (max-width: $layout-breakpoint-sm - 1) {
      @content;
    }
  }
  
  /// Greater then small devices. Above, and 960px.
  @mixin reducbox-media--gt-sm() {
    @media (min-width: $layout-breakpoint-sm) {
      @content;
    }
  }

  /// Greater then medium devices. Above, 1280px.
@mixin reducbox-media--gt-md() {
  @media (min-width: $layout-breakpoint-md) {
    @content;
  }
}